.youtube-videos-wrapper {
    padding: 4vw;
}
.youtube-videos-wrapper h1 {
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-Regular";
    font-size: 2vw;
    letter-spacing: 3px;
    text-align: center;
}
.slick-initialized .slick-slide{
    padding: 0vw 1vw;
}
.youtube-card {
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 5vw;
}
.youtube-card-img-wrapper {
    height: 350px;
}
.youtube-card-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.subscribe-yt-wrapper {
    display: flex;
    justify-content: center;
}
.subscribe-yt-wrapper button {
    border-radius: 10px;
    background: var(--primary-color-6, #3b7fef);
    border: none;
    padding: 1vw 3vw;
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 1.3vw;
    cursor: pointer;
}
@media (max-width: 600px) {
    .youtube-card-img-wrapper {
        height: 180px;
    }
    .youtube-videos-wrapper {
        margin: 8vw 0vw;
    }
    .youtube-videos-wrapper h1 {
        font-size: 4.5vw;
    }
    .subscribe-yt-wrapper {
        margin: 8vw 0vw;
    }
    .subscribe-yt-wrapper button {
        padding: 4vw 10vw;
        font-size: 3.5vw;
    }
    .youtube-card {
        width: 100% !important;
        margin: auto;
    }
    .youtube-card-img-wrapper img {
        object-fit: contain;
    }
}
