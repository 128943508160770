@font-face {
    font-family: "Poppins-SemiBold";
    src: url("./resources/fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("./resources/fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("./resources/fonts/Poppins.Medium.ttf");
}
@font-face {
    font-family: "Poppins-Bold";
    src: url("./resources/fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("./resources/fonts/Gilroy-Medium.ttf");
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url("./resources/fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: "Lato-Bold";
    src: url("./resources/fonts/Lato-Bold.ttf");
}
