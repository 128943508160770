.banner-container {
    background: var(--Success-Green-500, #FE6601);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vw 0vw;
    color: #fff;
    font-size: 1vw;
    font-family: "Poppins-Regular";
}

.banner-button {
    margin-left: 2vw;
    border: none;
    border-radius: 6px;
    color: var(--Success-Green-600, #FE6601);
    padding: 0.3vw 0.8vw;
    background-color: #fff;
    font-size: 1.1vw;
    font-family: "Poppins-SemiBold";
    cursor: pointer;
}

@media (max-width: 600px) {
    .banner-container {
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
    }
    .banner-container p {
        width: 70%;
    }
    .banner-button {
        margin-left: 1rem;
        font-size: 0.8rem;
        padding: 0.5rem 0.6rem;
    }
}
