.courses-dropDown-btn .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    text-transform: none;
}

.course-data-wrapper {
    display: flex;
    width: 39vw;
}
.course-name-wrapper {
    display: flex;
}

.course-name {
    background: linear-gradient(180deg, #444bab 0%, #3e8ebc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: "Poppins-Regular";
    font-weight: 600;
    padding-top: 0.5vw;
    font-size: 1vw;
    padding-right: 0.5vw;
    margin-right: 0.1vw;
}
.course-description {
    font-family: "Poppins-Regular";
    font-weight: 400;
    line-height: 1.5vw;
    color: #888888;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    white-space: break-spaces;
    font-size: 0.9vw;
}
.menu-bar {
    width: 100%;
    left: 10px !important;
}
.tittle-img-wrapper {
    display: flex;
}
.new-tag-img {
    position: relative;
    top: 0.5vw;
    width: 1.5vw !important;
    height: 1.5vw !important;
    min-width: 0 !important;
    min-height: 0 !important;
}
@media (max-width: 992px) {
    .m-0 {
        margin: 0;
    }
}
@media (max-width: 600px) {
    .course-description,
    .course-name {
        font-size: 12px;
        color: #888888;
    }
    .course-description {
        line-height: 19px;
    }
    .course-data-wrapper {
        width: 65vw;
        padding: 0;
    }
    .new-tag-img {
        width: 18px !important;
        height: 18px !important;
    }
}
