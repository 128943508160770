.reg-now-modal-wrapper-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reg-now-modal-wrapper {
    position: relative;
    width: 30%;
    border-radius: 20px;
    background: var(--dark-mode-500, #292c33);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2vw;
    pointer-events: auto;
}

.reg-now-modal-close {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 10%;
    right: 3%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.reg-now-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reg-now-modal-text {
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 1vw;
    text-align: center;
}

.reg-now-user-img {
    width: 40%;
}
.reg-now-user-img img {
    width: 100%;
}

.reg-now-btn-wrapper {
    padding: 1vw;
}
.reg-now-btn-wrapper button {
    border-radius: 6px;
    background: #3b7fef;
    border: none;
    padding: 1vw 1.5vw;
    color: #fff;
    font-size: 1vw;
    font-family: "Poppins-Regular";
    cursor: pointer;
}

.reward-modal-wrapper-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reward-modal-wrapper {
    position: relative;
    width: 30%;
    border-radius: 20px;
    background: var(--dark-mode-500, #292c33);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2vw;
    pointer-events: auto;
}

.reward-modal-close {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 10%;
    right: 3%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.reward-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reward-modal-text {
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 1.2vw;
    text-align: center;
}
.google-form-text {
    color: #fff;
    text-align: center;
    font-size: 1vw;
    font-family: "Poppins-Regular";
    padding: 2vw;
}
.reward-blue-text {
    color: var(--primary-color-6, #3b7fef);
    font-family: "Poppins-SemiBold";
    font-size: 1vw;
}
.reward-user-img {
    width: 40%;
}
.reward-user-img img {
    width: 100%;
}

.reward-btn-wrapper {
    padding: 1vw;
}
.reward-btn-wrapper button {
    border-radius: 6px;
    background: #3b7fef;
    border: none;
    padding: 1vw 3vw;
    color: #fff;
    font-size: 1vw;
    font-family: "Poppins-Regular";
    cursor: pointer;
}

.redeem-modal-wrapper-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.redeem-modal-wrapper {
    position: relative;
    width: 30%;
    border-radius: 20px;
    background: var(--dark-mode-500, #292c33);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2vw;
    pointer-events: auto;
}

.redeem-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.redeem-modal-heading {
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 1.2vw;
    text-align: center;
    padding-bottom: 1vw;
}
.redeem-modal-text {
    color: #fff;
    font-family: "Poppins-Regular";
    font-size: 1vw;
    text-align: center;
}

.redeem-btn-wrapper {
    padding: 1vw;
}
.redeem-btn-wrapper button {
    border-radius: 6px;
    border: none;
    padding: 1vw 3vw;
    color: #fff;
    font-size: 1vw;
    font-family: "Poppins-Regular";
    cursor: pointer;
}
.no-btn {
    background: var(--neutral-500, #64748b);
    margin-right: 1vw;
}
.yes-btn {
    background: #3b7fef;
}

@media (max-width: 600px) {
    .reg-now-modal-wrapper {
        width: 70%;
        padding: 4vw;
    }
    .reg-now-modal-close {
        width: 1rem;
        height: 1rem;
    }
    .reg-now-modal-text {
        font-size: 3vw;
    }
    .reg-now-btn-wrapper {
        margin-top: 2vw;
    }
    .reg-now-btn-wrapper button {
        padding: 2vw 4vw;
        font-size: 3vw;
    }
    .reward-modal-wrapper {
        width: 70%;
        padding: 4vw;
    }
    .reward-modal-text {
        font-size: 3vw;
    }
    .reward-btn-wrapper {
        margin-top: 2vw;
    }
    .reward-btn-wrapper button {
        padding: 2vw 3vw;
        font-size: 3vw;
    }

    .redeem-modal-wrapper {
        width: 70%;
        padding: 4vw;
    }
    .redeem-modal-text {
        font-size: 3vw;
    }
    .redeem-btn-wrapper {
        margin-top: 2vw;
    }
    .redeem-btn-wrapper button {
        padding: 2vw 5vw;
        font-size: 3vw;
    }
    .no-btn {
        margin-right: 2.5vw;
    }
    .redeem-modal-heading {
        font-size: 3.5vw;
    }
    .google-form-text {
        font-size: 2.5vw;
    }

    .reward-blue-text {
        font-size: 2.5vw;
    }
}
