.intro-section-wrapper {
    padding: 4.5vw 0vw 4vw 0vw;
}
.intro-section-wrapper h1 {
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-SemiBold";
    font-size: 2.6vw;
    text-align: center;
    padding-bottom: 1vw;
    letter-spacing: 1px;
}
.intro-section-desc {
    color: var(--neutral-100, #e7eaee);
    font-family: "Poppins-Regular";
    font-size: 1.3vw;
    width: 45%;
    margin: auto;
    text-align: center;
    letter-spacing: 1px;
}
.intro-gallery-form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 3vw;
}
.gallery-row-images {
    display: flex;
}

.gallery-column-images {
    display: flex;
    flex-direction: column;
}
.column-img {
    margin: 0.6vw;
}

.column-img-small {
    display: flex;
    justify-content: center;
}

.intro-form-wrapper {
    display: flex;
    width: 35%;
    margin: 0vw 1.5vw;
    flex-direction: column;
    border-radius: 20px;
    padding: 2vw;
    border: 1px solid var(--neutral-400, #a0abbb);
    background: var(--dark-mode-500, #292c33);
}
.intro-form-wrapper h2 {
    font-size: 2.5vw;
    color: var(--shades-white, #fff);
    font-family: "Poppins-Bold";
    padding-bottom: 2vw;
    text-align: center;
}
.register-form-loader {
    display: flex;
    justify-content: center;
}
.intro-form {
    display: flex;
    flex-direction: column;
}
.intro-form input {
    border-radius: 6px;
    border: 1px solid var(--neutral-400, #a0abbb);
    background: var(--dark-mode-800, #20232a);
    padding: 1vw;
    margin: 0.5vw 0vw;
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    color: #fff;
    width: 100%;
}
.react-tel-input .selected-flag {
    background: var(--dark-mode-800, #20232a);
}
.react-tel-input .flag-dropdown.open .selected-flag {
    background: var(--dark-mode-800, #20232a);
}
.react-tel-input {
    margin: 0.5vw 0vw;
}

.intro-form input::placeholder {
    color: var(--neutral-50, #f7f8f9);
}
.join-community-btn-wrapper {
    margin: 1vw 0vw;
}
.join-community-btn-wrapper button {
    color: var(--shades-white, #fff);
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    width: 100%;
    border-radius: 6px;
    padding: 1vw;
    background: var(--primary-color-6, #3b7fef);
    border: none;
    cursor: pointer;
}
.email-wati-text {
    text-align: center;
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-Regular";
    font-size: 1vw;
}

.community-page-form-item-error {
    color: red !important;
    font-family: "Poppins-Regular" !important;
    font-size: 0.9vw !important;
    width: 100% !important;
}
.user-reg-error {
    margin: 1vw 0vw 0vw 0vw;
    text-align: center;
}
.community-submitted-modal-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.community-submit-modal {
    position: relative;
    width: 45%;
    border-radius: 20px;
    background: var(--dark-mode-500, #292c33);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vw;
    pointer-events: auto;
}
.community-submit-modal-greeting {
    text-align: center;
    font-family: "Poppins-Regular";
    font-weight: 700;
    font-size: 1.4vw;
    color: #ffffff;
    padding: 2vw 0vw;
}
.close-icon {
    cursor: pointer;
    position: absolute;
    top: 5%;
    right: 5%;
    width: 2rem;
    height: 2rem;
}
.shake-hand-img {
    width: 15rem;
    margin: 1vw 0vw;
}
.community-submit-modal-info {
    font-family: "Poppins-Regular";
    text-align: center;
    width: 25vw;
    font-weight: 550;
    font-size: 1vw;
    color: #ffffff;
    padding-bottom: 1vw;
}

.community-submit-modal-info span {
    color: #3b7fef;
}

.community-submit-modal-head {
    justify-content: center;
    align-items: center;
}
.community-submit-modal-body {
    justify-items: center;
    align-items: center;
}
.share-msg {
    color: #ffffff;
    font-size: 1vw;
    font-family: "Poppins-Regular";
    text-align: center;
    padding: 0vw 1vw;
    padding-top: 1.5vw;
}
.share-icons-wrapper {
    display: flex;
    padding-top: 2vw;
}
.share-icon-img {
    width: 2rem;
    height: 2rem;
    margin: 0vw 0.5vw;
}
@media (max-width: 600px) {
    .intro-section-wrapper h1 {
        font-size: 5.5vw;
        padding: 2vw 15vw 2vw 15vw;
    }
    .intro-section-desc {
        font-size: 3.5vw;
        width: 75%;
    }
    .intro-form-wrapper {
        width: 95%;
        margin: 6vw;
        border-radius: 10px;
        padding: 4vw 3vw;
    }
    .intro-form-wrapper h2 {
        font-size: 6.5vw;
        padding-bottom: 2vw;
    }
    .intro-form input {
        border-radius: 4px;
        padding: 3vw;
        margin: 2vw 0vw;
        font-size: 3.5vw;
    }
    .join-community-btn-wrapper {
        margin: 4vw 2vw;
    }
    .join-community-btn-wrapper button {
        font-size: 3.5vw;
        width: 100%;
        border-radius: 4px;
        padding: 3vw;
    }
    .email-wati-text {
        margin: 3vw 3vw;
        font-size: 3.1vw;
    }

    .community-page-form-item-error {
        color: red !important;
        font-family: "Poppins-Regular" !important;
        font-size: 0.9vw !important;
        width: 100% !important;
    }
    .community-submit-modal {
        width: 88%;
    }
    .community-submit-modal-greeting {
        padding-top: 4vh;
        font-size: 3vw;
    }
    .community-submit-modal-info {
        width: 70vw;
        font-size: 2.5vw;
        padding: 4vw 0vw;
    }
    .react-tel-input .form-control {
        height: 40px !important;
        font-size: 3.5vw !important;
    }
    .close-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
    .shake-hand-img {
        width: 10rem;
        margin: 3vw 0vw;
    }
    .share-msg {
        font-size: 2.5vw;
        padding: 2vw 0vw;
    }
    .share-icons-wrapper {
        padding: 3vw;
    }
    .share-icon-img {
        width: 1.5rem;
        height: 1.5rem;
    }
}
