.how-does-it-work-wrapper {
    background: #292c33;
}

.how-does-it-work-heading {
    font-family: Lato-Bold;
    font-weight: 800;
    font-size: 3vw;
    color: #f7f8f9;
    text-align: center;
    padding: 3vw 0;
    padding-top: 4.5vw;
}

.steps-and-cards-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.stepper-image-wrapper {
    padding-right: 4vw;
}
.steps-card-and-content-wrapper {
    display: flex;
    padding-bottom: 1.5vw;
    align-items: center;
}
.step-card-description {
    padding-left: 4vw;
    text-align: center;
    font-family: Lato-Bold;
    color: #f7f8f9;
    font-size: 1.3vw;
}
.card-coin-image {
    width: 7%;
    padding: 0 0.5vw;
}

.gift-box {
    width: 8%;
    padding: 0 0.2vw;
    padding-top: 0.2vw;
}
.steps-coin-image {
    width: 5%;
    margin: 1%;
    margin-bottom: -1%;
}
@media (max-width: 600px) {
    .how-does-it-work-wrapper {
        background: #14171f;
    }
    .card-coin-image,
    .gift-box {
        width: 8%;
        padding: 0 1vw;
    }
    .step-card-description {
        font-size: 16px;
        padding-top: 4vw;
    }
    .steps-card-and-content-wrapper {
        flex-direction: column;
    }
    .steps-and-cards-wrapper {
        padding-top: 5vw;
        align-items: unset;
    }
    br {
        display: none;
    }
    .stepper-image-wrapper {
        align-items: stretch;
        height: 100%;
        padding-top: 18%;
        width: 15%;
        padding-left: 2%;
    }

    .steps-and-cards-wrapper {
        padding: 3vw 6vw;
        padding-top: 10vw;
    }
    .how-does-it-work-heading {
        font-size: 5vw;
        padding-top: 8vw;
    }
}
