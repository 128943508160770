.what-section-wrapper {
    padding: 4vw;
    background: var(--dark-mode-500, #292c33);
    margin: 4vw 0vw;
}
.what-heading {
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-Regular";
    font-size: 2vw;
    letter-spacing: 3px;
    text-align: center;
    padding-bottom: 2vw;
}
.what-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.dev-image {
    width: 20rem;
    height: 18rem;
}
.what-content {
    width: 50%;
    font-size: 1.2vw;
    font-family: "Poppins-Regular";
    color: var(--neutral-50, #f7f8f9);
}
.blue-text {
    color: var(--primary-color-6, #3b7fef);
    font-family: "Poppins-SemiBold";
}

.why-section-wrapper {
    padding: 4vw;
    background: var(--dark-mode-500, #292c33);
}
.why-heading {
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-Regular";
    font-size: 2vw;
    letter-spacing: 3px;
    text-align: center;
    padding-bottom: 2vw;
}
.why-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 3vw;
}
.consistency-img {
    width: 22rem;
    height: 20rem;
}
.what-list {
    width: 50%;
}
.what-list ul {
    display: flex;
    align-items: center;
    font-family: "Poppins-Regular";
    color: var(--neutral-50, #f7f8f9);
    font-size: 1.2vw;
    padding: 1vw;
}
.what-list ul img {
    margin-right: 0.5vw;
}
.why-list {
    width: 50%;
}
.why-list ul {
    font-family: "Poppins-Regular";
    color: var(--neutral-50, #f7f8f9);
    font-size: 1.2vw;
    padding: 1vw;
}
.why-gift-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2vw;
}
.gift-text {
    font-family: "Poppins-SemiBold";
    font-size: 1.2vw;
    color: var(--primary-color-6, #3b7fef);
}

.about-section-wrapper {
    padding: 4vw;
    background: var(--dark-mode-500, #292c33);
    margin: 4vw 0vw;
}
.about-heading {
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-Regular";
    font-size: 2vw;
    letter-spacing: 3px;
    text-align: center;
}
.about-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 4vw;
}
.about-content {
    width: 50%;
    font-size: 1.2vw;
    font-family: "Poppins-Regular";
    color: var(--neutral-50, #f7f8f9);
}

.refer-earn-section-wrapper {
    padding: 4vw;
    background: var(--dark-mode-500, #292c33);
    margin: 4vw 0vw;
}

.refer-earn-img {
    width: 18rem;
    height: 14rem;
}
.refer-earn-section-wrapper h1 {
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-Regular";
    font-size: 2vw;
    letter-spacing: 3px;
    text-align: center;
}

.refer-earn-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 3vw;
}

.refer-earn-content {
    width: 50%;
    font-size: 1.2vw;
    font-family: "Poppins-Regular";
    color: var(--neutral-50, #f7f8f9);
}
.refer-earn-btn-wrapper {
    margin: 1vw 0vw;
}
.refer-earn-btn-wrapper button {
    color: var(--shades-white, #fff);
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    width: 20%;
    border-radius: 6px;
    padding: 0.8vw;
    background: var(--primary-color-6, #3b7fef);
    border: none;
    cursor: pointer;
}

@media (max-width: 600px) {
    .what-heading {
        font-size: 4.5vw;
    }
    .what-content-wrapper {
        flex-direction: column;
    }
    .what-content-wrapper img {
        margin-top: 2vw;
    }
    .what-content {
        font-size: 2.5vw;
        text-align: center;
        width: 100%;
        padding: 1.5vw 2.5vw;
    }
    .why-heading {
        font-size: 4.5vw;
    }
    .why-content-wrapper {
        flex-direction: column;
    }
    .why-content-wrapper img {
        width: 75%;
    }
    .why-list {
        width: 100%;
        padding: 4vw;
    }
    .why-list ul {
        font-size: 3.5vw;
    }
    .what-list {
        width: 100%;
        padding: 4vw;
    }
    .what-list ul {
        font-size: 3.5vw;
    }
    .gift-text {
        font-size: 3.5vw;
        padding-right: 1vw;
    }
    .why-gift-wrapper {
        margin: 3vw 0vw;
    }
    .why-gift-wrapper img {
        width: 2rem;
        height: 2rem;
    }
    .about-heading {
        font-size: 4.5vw;
    }
    .about-content-wrapper {
        flex-direction: column;
    }
    .about-content-wrapper img {
        width: 30%;
    }
    .about-content {
        width: 100%;
        text-align: center;
        font-size: 3.5vw;
        padding: 8vw 0vw 4vw 0vw;
    }
    .what-list ul img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 3vw;
    }
    .refer-earn-section-wrapper h1 {
        font-size: 4.5vw;
        padding-bottom: 3vw;
    }
    .refer-earn-content-wrapper {
        display: flex;
        flex-direction: column;
    }
    .refer-earn-content {
        text-align: center;
        font-size: 3.5vw;
        width: 100%;
        margin-top: 3vw;
    }
    .refer-earn-btn-wrapper button {
        width: 100%;
        font-size: 3.5vw;
        padding: 3vw;
        margin: 4vw 0vw;
    }
    .blue-text {
        font-size: 3.5vw;
    }
}
