.problem-solving-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: var(--dark-mode-500, #292c33);
    padding: 4vw;
    margin-top: 4vw;
}
.problem-solving-section-wrapper h1 {
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-Regular";
    font-size: 2vw;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 4vw;
}
.group-coding-img {
    width: 25rem;
    height: 20rem;
}
.join-community-button-wrapper {
    width: 35%;
    margin: auto;
}
.join-community-button-wrapper button {
    color: var(--shades-white, #fff);
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
    width: 100%;
    border-radius: 6px;
    padding: 1vw;
    background: var(--primary-color-6, #3b7fef);
    border: none;
    cursor: pointer;
}

@media (max-width: 600px) {
    .problem-solving-section-wrapper {
        flex-direction: column;
    }
    .problem-solving-section-wrapper h1 {
        font-size: 3.5vw;
    }
    .join-community-button-wrapper {
        width: 100%;
    }
    .join-community-button-wrapper button {
        padding: 2vw;
        font-size: 3.5vw;
    }
}
