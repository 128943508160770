.show-leaderboard-wrapper {
    background: #14171f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3vh;
}

.show-leaderboard-wrapper h1 {
    color: var(--neutral-100, #e7eaee);
    text-align: center;
    font-family: Poppins-Bold;
    font-size: 3vw;
    margin: 5vh 0 2vh 0;
}

.show-leaderboard-wrapper p {
    color: var(--neutral-100, #e7eaee);
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 1.5vw;
    margin-bottom: 3vh;
}

.show-leaderboard-content-wrapper {
    display: flex;
    flex-direction: row;
}

.leaderboard-left-gift,
.leaderboard-right-gift {
    margin: 4vw;
}

.leaderboard-right-gift img,
.leaderboard-left-gift img {
    width: 90%;
}

.leaderboard-right-gift {
    display: flex;
    flex-direction: column-reverse;
}
.leaderboard-content {
    display: flex;
    flex-direction: row;
}
.leaderboard-table-container {
    color: #fff;
    text-align: left;
    font-family: Poppins-Regular;
    font-size: 1vw;
    background: #292c33;
    border-radius: 20px;
}

.leaderboard-table-container th,
.leaderboard-table-container td {
    text-align: center;
    font-size: 1.5vw;
    padding: 1.8vw 2.4vw;
}
.leaderboard-table-container td img {
    width: 18%;
    margin-right: 0.5vw;
}
.blue-row td {
    color: var(--primary-color-6, #3b7fef);
}
.user-name {
    text-align: left !important;
}
.user-points {
    display: flex;
    align-items: center;
    text-align: left !important;
}

@media (max-width: 600px) {
    .show-leaderboard-wrapper {
        padding: 10vw 0vw;
    }
    .show-leaderboard-content-wrapper {
        width: 100%;
    }
    .leaderboard-table-container {
        border-radius: 0px;
        width: 100%;
    }
    .leaderboard-table-container table {
        width: 100%;
        padding: 4vw 0vw;
    }

    .leaderboard-table-container th,
    .leaderboard-table-container td {
        font-size: 3.5vw;
    }
    .user-points img {
        width: 1rem !important;
        height: 1rem;
        margin-right: 1vw !important;
    }
    .show-leaderboard-wrapper h1 {
        font-size: 4.5vw;
    }
    .show-leaderboard-wrapper p {
        font-size: 3.5vw;
    }
}
