.linear-gradient-btn-container {
    display: flex;
    align-items: center;
}

.linear-gradient-btn {
    padding: 11.25px 25px;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(#444bab 0%, #3e8ebc 100%);
    cursor: pointer;
    font-family: "Poppins-Semibold";
    white-space: nowrap;
}

.linear-gradient-btn::before {
    content: "";
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 4px;
    background-color: white;
    z-index: -1;
    transition: 200ms;
}

.linear-gradient-btn::after {
    content: attr(data);
    font-size: 1.1vw;
    background: linear-gradient(#444bab 0%, #3e8ebc 100%);
    -webkit-background-clip: text;
    color: transparent;
}

@media (max-width: 600px) {
    .linear-gradient-btn {
        padding: 10px 30px;
    }
    .linear-gradient-btn::after {
        content: attr(data);
        font-size: 14px;
    }
}
