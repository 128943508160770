.earn-coins-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3vw;
    padding-bottom: 1.5vw;
    position: relative;
}

.earn-coins-title-wrapper h3 {
    font-family: Poppins-Bold;
    font-weight: 800;
    font-size: 3vw;
    color: #f7f8f9;
    font-size: 3.5vw;
}
.earn-coins-wrapper {
    position: relative;
    background: #292c33;
    padding: 0 4vw;
}
.coin-refferal-to-point-conversion {
    text-align: center;
    padding-bottom: 2vw;
    font-size: 1.3vw;
    font-weight: 700;
    color: #f7f8f9;
    font-family: Lato-Bold;
}
.refer-gift-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.refer-gift-card {
    padding: 1vw;
    width: 25%;
    background: #14171f;
    border: 1px solid #b8c0cc;
    border-radius: 0.5vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 2vw;
    margin-bottom: 5vw;
}
.card-details-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.5vw;
    align-items: center;
}
.card-coin-box {
    display: flex;
    align-items: center;
    background: #231f20;
    border-radius: 0.4vw;
    color: #fff;
    font-family: Lato-Bold;
    padding: 0.5vw;
}
.card-coin-box > p {
    padding-right: 0.5vw;
}

.card-coin-box img {
    width: 23%;
}
.refer-card-desc {
    font-family: Lato-Bold;
    color: #f7f8f9;
    font-size: 1.1vw;
}
.gift-image-wrapper > img {
    width: 100%;
    height: auto;
}
.redeem-points-btn {
    display: flex;
    padding: 12px 28px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Poppins-Regular;
    font-size: 1.3vw;
    align-self: stretch;
    border-radius: 6px;
    margin-top: 2vh;
    cursor: pointer;
}
.redeem-points-btn-disabled {
    background: var(--neutral-400, #a0abbb);
}
.redeem-points-btn-enabled {
    background: var(--primary-color-6, #3b7fef);
}
.reward-form-loader {
    position: absolute;
    top: 35vw;
    left: 47vw;
}
.MuiSnackbar-anchorOriginBottomLeft{
    width: 75%;
}
@media (max-width: 600px) {
    .refer-gift-cards-wrapper {
        padding: 0vw;
        padding-top: 6vw;
    }
    .refer-gift-card {
        width: 45%;
        padding: 3vw;
        border-radius: 1vw;
    }
    .refer-card-desc {
        font-size: 2.9vw;
    }
    .card-coin-box {
        justify-content: center;
        width: 100%;
        border-radius: 1vw;
        margin-top: 2vw;
        padding: 1.5vw;
    }
    .coin-refferal-to-point-conversion {
        font-size: 15px;
    }
    .earn-coins-title-wrapper h3 {
        font-size: 5vw;
    }
    .earn-coins-title-wrapper {
        padding-top: 8vw;
    }
    .redeem-points-btn-disabled {
        font-size: 3.5vw;
    }
    .card-details-wrapper {
        flex-direction: column;
    }

    .card-coin-box img {
        width: 10%;
    }
    .redeem-points-btn {
        padding: 1vw;
    }
    .redeem-points-btn-enabled {
        font-size: 3.5vw;
    }
    .reward-form-loader {
        left: 45vw;
        top: 80vh;
    }
}
