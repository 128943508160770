.header-buttons {
    display: flex;
    align-items: center;
}
.header-buttons > div {
    margin: 0 0.8vw;
}
.header-container {
    background-color: white;
    box-shadow: 0px 1px 10px #e5e6e7;
    position: sticky;
    padding: 1vw 15px;
}

.header-main-container {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 100%;
    margin: 0 auto;
}
.ppa-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ppa-header-logo {
    max-width: 19vw !important;
    height: 3vw;
    margin-top: 2px !important;
    cursor: pointer;
}
.header-ppa-nav-container {
    display: flex;
    flex-direction: row;
    width: 25%;
    justify-content: space-between;
}
.header-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 0 0.5vw;
}
.header-nav-links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2vw;
    padding: 0 6vw;
}

.header-nav-link {
    color: #4f516b;
    font-family: "Gilroy-Medium";
    text-decoration: none;
    font-size: 1vw;
    margin: 0 1vw;
    white-space: nowrap;
    cursor: pointer;
}
.side-nagivation-list-mob {
    font-family: "Gilroy-Medium";
}
.log-in-or-out-btn {
    font-family: "Poppins-SemiBold";
    font-size: 1.18vw;
    color: #444bab;
    white-space: nowrap;
    cursor: pointer;
}
.sign-up-btn {
    color: white;
    background: linear-gradient(93.53deg, #444bab 0%, #416bb3 62.5%, #3ca5c2 100%);
    font-family: "Poppins-SemiBold";
    text-transform: "capitalize";
    font-size: 1.17vw;
    white-space: nowrap;
    border: none;
    padding: 0.725vw 25px;
    border-radius: 5px;
    cursor: pointer;
}
.mob-course-drop-down {
    color: #4f516b;
    font-family: "Gilroy-Medium";
}
@media (max-width: 992px) {
    .header-nav-link {
        font-size: 1.3vw;
        margin: 0 10px;
    }

    .header-nav-links-container {
        padding-right: 0;
    }
    .log-in-or-out-btn {
        font-size: 1.3vw;
    }
    .ppa-header-logo {
        max-width: 15vw !important;
    }
}
@media (max-width: 600px) {
    .log-in-or-out-btn {
        font-size: 14px;
        padding-right: 1vw;
    }
    .ppa-logo {
        width: 180px !important;
        height: 100% !important;
    }
    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2vw 2vw;
    }
    .ppa-header-logo {
        max-width: 40px;
    }
    .ppa-header-logo .login-screen-mob-wrapper {
        background-color: #4f516b;
        z-index: 4;
    }
    .ppa-logo-mob {
        width: 70%;
    }
    .side-navigation-header-wrapper {
        padding: 10px 15px;
        display: flex;
        gap: 30px;
        align-items: center;
    }
    .side-navigation-links {
        font-family: "Gilroy-Medium";
        text-decoration: none;
        color: #4f516b;
    }

    .side-nagivation-list-mob {
        margin-top: 30px;
        padding-left: 10px;
        color: #4f516b;
        display: flex;
        flex-direction: column;
        color: #4f516b;
        font-family: "Gilroy-Medium";
    }
    .side-nagivation-list-mob a {
        margin-bottom: 20px;
        margin-left: 15px;
        font-size: 16px;
        color: #4f516b;
        font-family: "Gilroy-Medium";
    }
    .side-nagivation-list-mob :hover {
        color: #444bab;
    }

    .side-nagivation-login-trail-btn {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 25px;
    }
    .side-navigation-login-btn {
        margin-bottom: 2vw;
    }
    .mob-course-drop-down {
        padding-left: 3vw;
        padding-bottom: 3vw;
    }

    .drop-down-wrapper {
        background: #ffffff;
        border: 1px solid rgba(174, 178, 203, 0.22);
        border-radius: 8px;
        padding: 10px;
        margin-right: 2vw;
        margin-left: 2vw;
        margin-bottom: 3vw;
    }
    .drop-down-menu {
        border-bottom: 1px solid rgba(174, 178, 203, 0.22);
        padding: 1.5vw 0;
    }
}
