.explore-courses-wrapper {
    padding: 4vw;
}
.explore-courses-wrapper h1 {
    color: var(--neutral-50, #f7f8f9);
    font-family: "Poppins-Regular";
    font-size: 2vw;
    letter-spacing: 3px;
    text-align: center;
}

.courses-wrapper {
    padding: 3vw 0vw;
    padding-bottom: 0vw;
}
.courses-wrapper .slick-list {
    margin: 0vw 1vw;
}

.course-card {
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5vw;
    height: 28vw;
}
.course-card-img-wrapper {
    background: black;
}
.course-card-img-wrapper img {
    width: 100%;
    height: 100%;
}
.course-card-desc {
    color: #353535;
    font-family: Poppins-Regular;
    font-size: 1.1vw;
    padding: 1vw;
}
.course-card-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-SemiBold";
    background-color: #ffffff;
    border: none;
    position: absolute;
    bottom: 5%;
    padding-left: 1.5vw;
    color: #444bab;
    text-align: center;
}
.know-more-txt {
    font-size: 1.25vw;
}
.arrow-right {
    width: 12%;
    margin-left: 0.5vw;
}

@media (max-width: 600px) {
    .explore-courses-wrapper {
        padding-top: 6vw;
    }
    .explore-courses-wrapper h1 {
        font-size: 4.5vw;
        padding-bottom: 4vw;
    }
    .courses-wrapper h1 {
        font-size: 6vw;
    }

    .course-card {
        width: 100% !important;
        height: 80vw;
        border-radius: 3.6vw;
    }
    .course-card-desc {
        font-size: 3.1vw;
        padding: 3.1vw;
    }
    .course-card-btn {
        padding-left: 3.1vw;
    }
    .know-more-txt {
        font-size: 3.4vw;
        margin-top: 5px;
    }

    .slick-slider {
        margin: 0vw 5vw;
    }
}
