.show-referral-code-wrapper {
    background: #14171f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 6vh;
}
.show-referral-code-wrapper h1 {
    padding-top: 6vh;
    font-size: 3.5vw;
    padding-bottom: 2vh;
    color: #f7f8f9;
}
.show-referral-code-wrapper-desc {
    color: #f7f8f9;
    font-weight: 500 !important;
}

.show-referral-code-phone-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 1vh;
}
.show-referral-code-phone-input input {
    padding: 1vw 4vw;
}
.referral-code-check-btn {
    display: inline-flex;
    padding: 1.1vw 2.1vw;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--dark-mode-900, #14171f);
    color: #ffffff;
    margin-left: 1vw;
    cursor: pointer;
}
.show-points-and-referral-code {
    display: flex;
    flex-direction: row;
    color: #f7f8f9;
    margin-top: 2vh;
}
.total-points-box {
    display: flex;
    padding: 0vw 1.5vw;
    height: 54px;
    align-items: center;
    border-radius: 10px;
    background: var(--dark-mode-500, #292c33);
    color: #f7f8f9;
    margin-right: 2vw;
    font-size: 1vw;
}
.total-points-box img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5vw;
}
.referral-code-box {
    display: flex;
    height: 54px;
    padding: 0vw 1.5vw;
    align-items: center;
    border-radius: 10px;
    background: var(--dark-mode-500, #292c33);
    color: #f7f8f9;
    font-size: 1vw;
    cursor: pointer;
}
.referral-code-box img {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5vw;
}

.referral-code-loader {
    display: flex;
    justify-content: center;
}
.error-phone-number {
    color: red;
}
.blank-line {
    border: 3px solid white;
    width: 20px;
    margin: 1vw 0vw;
    margin-right: 0.5vw;
}
.user-points-text {
    font-family: "Poppins-SemiBold";
    font-size: 1.2vw;
    padding-right: 0.3vw;
}
.copy-msg {
    color: white;
    padding: 0.5vw 0vw;
}
.total-points-txt {
    font-size: 1vw;
}
.your-points {
    padding: 0.5vw 0vw;
}

.your-code {
    padding: 0.5vw 0vw;
}
@media (max-width: 600px) {
    .show-referral-code-wrapper h1 {
        font-size: 4.5vw;
    }
    .referral-code-check-btn {
        padding: 3vw 4vw;
    }
    .show-points-and-referral-code {
        flex-direction: column;
        width: 100%;
        padding: 3vw;
    }
    .show-points-section {
        width: 100%;
    }
    .total-points-box {
        padding: 3vw 0vw;
        margin: 0vw 2vw;
        justify-content: center;
    }
    .total-points-box img {
        width: 1rem;
        height: 1rem;
    }
    .blank-line {
        margin: 0vw 2vw;
    }

    .referral-code-box {
        padding: 3vw 0vw;
        margin: 0vw 2vw;
        justify-content: center;
        font-size: 3.5vw;
    }
    .referral-code-box img {
        font-size: 2.5vw;
    }
    .your-points {
        padding: 2vw;
    }
    .your-code {
        padding: 2vw;
    }
    .user-points-text {
        font-size: 4vw;
        margin: 0vw 2vw;
    }
    .total-points-txt {
        font-size: 3.5vw;
    }
}
